var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('v-container',{staticStyle:{"max-width":"900px"}},[_c('v-row',{staticClass:"my-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('h1',[_vm._v(" สมัครสมาชิก ")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"pa-5 app-card"},[_c('v-text-field',{attrs:{"label":"เบอร์โทรศัพท์","filled":"","rounded":"","dense":""},on:{"keypress":function($event){return _vm.allowOnlyNumber($event)},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"type":"password","label":"รหัสผ่าน","filled":"","rounded":"","dense":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-divider',{staticClass:"mb-5"}),_c('v-select',{attrs:{"items":[
              { text: 'ธนาคารกสิกรไทย', value: 'kbank' },
              { text: 'ธนาคารไทยพานิชย์', value: 'scb' },
              { text: 'ธนาคารกรุงไทย', value: 'ktb' },
              { text: 'ธนาคารกรุงเทพ', value: 'bbl' },
              { text: 'ธนาคารทหารไทยธนชาต', value: 'ttb' },
              { text: 'ธนาคารทหารไทย', value: 'tmb' },
              { text: 'ธนาคาร UOB', value: 'uob' },
              { text: 'ธนาคารกรุงศรีอยุธยา', value : 'bay'},
              { text: 'ธนาคารออมสิน', value : 'gsb'},
              { text: 'ธนาคาร ธกส', value: 'baac' },
              { text: 'ธนาคารเกียรตินาคินภัทร', value: 'kk' } ],"label":"โปรดเลือกธนาคารของท่าน","filled":"","rounded":"","dense":""},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}}),_c('v-text-field',{attrs:{"label":"เลขบัญชีธนาคาร/ทรูมันนี่","filled":"","rounded":"","dense":""},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}}),_c('v-text-field',{attrs:{"label":"ชื่อ-นามสกุลหน้าเล่ม","filled":"","rounded":"","dense":""},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}}),(_vm.bank === 'truemoney')?_c('small',{staticClass:"red--text"},[_vm._v(" กรุณากรอก ชื่อ(เว้นวรรค)นามสกุล ไม่ใส่คำนำหน้า สำคัญกรุณาเช็คให้ทุกต้องทุกตัวอักษรเช่น ดวงดี มั่งมี ")]):_vm._e(),_c('v-divider',{staticClass:"mb-5"}),_c('v-select',{attrs:{"items":[
              { text: 'Facebook', value: 'facebook' },
              { text: 'Youtube', value: 'youtube' },
              { text: 'Search Engine', value: 'search_engine' },
              { text: 'SMS โฆษณา', value: 'sms' },
              { text: 'เพื่อนแนะนำ', value: 'friend' },
              { text: 'ตัวแทนของเรา', value: 'agent' } ],"label":"ท่านเราจักเราผ่านช่องทางใด","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.knownUsFrom),callback:function ($$v) {_vm.knownUsFrom=$$v},expression:"knownUsFrom"}}),_c('v-btn',{staticClass:"mt-5 app-primary",attrs:{"block":"","rounded":"","depressed":"","loading":_vm.loading},on:{"click":function($event){return _vm.registerUser()}}},[_vm._v(" สมัครสมาชิก ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }